/* eslint-disable import/prefer-default-export */

export const newsList = [
  {
    id: '18',
    thumbFilename: 'news/media-cover-images/sale-banner.png',
    logoFilename: 'news/logos/mint.png',
    des: 'Klub raises ₹200 cr for its revenue-based fund Aceler8',
    date: '15 Dec 2021',
    link: 'https://www.livemint.com/companies/start-ups/klub-raises-200-cr-for-its-revenue-based-fund-aceler8-11639490648617.html'
  },
  {
    id: '17',
    thumbFilename: 'news/media-cover-images/transteel.png',
    logoFilename: 'news/logos/disrupt.png',
    des: 'Transteel Raises Rs 4Cr Through Klub',
    date: '09 Oct 2021',
    link: 'http://bwdisrupt.businessworld.in/article/Transteel-Raises-Rs-4Cr-Through-Klub/09-10-2021-408047/'
  },
  {
    id: '16',
    thumbFilename: 'news/media-cover-images/trifecta-capital.png',
    logoFilename: 'news/logos/YOURSTORY.png',
    des: 'Klub raises Rs 20Cr in debt from Trifecta Capital',
    date: '20 Sep 2021',
    link: 'https://yourstory.com/2021/09/revenue-based-financing-klub-raises-debt-fund-trifecta-capital/amp'
  },
  {
    id: '15',
    thumbFilename: 'news/media-cover-images/seed-funding.png',
    logoFilename: 'news/logos/et.png',
    des: 'Klub gets $20 million funding, to deploy Rs 500 crore for local brands',
    date: '17 Aug 2021',
    link: 'https://economictimes.indiatimes.com/tech/funding/klub-gets-20-million-funding-to-deploy-rs-500-crore-for-local-brands/articleshow/85394848.cms'
  },
  {
    id: '14',
    des: 'KLUB: Flexible growth capital for brands',
    thumbFilename: 'news/media-cover-images/financial-express-march-21.jpeg',
    logoFilename: 'news/logos/fn-express.png',
    date: '1 Mar 2021',
    link: 'https://www.financialexpress.com/industry/technology/klub-flexible-growth-capital-for-brands/2203058/'
  },
  {
    id: '13',
    des: 'E-Commerce lifestyle brand The Label Life raises funding through Klub',
    thumbFilename: 'news/media-cover-images/business-standard-Jan-20.jpg',
    logoFilename: 'news/logos/business-standard-logo.png',
    date: '20 Jan 2021',
    link: 'https://www.business-standard.com/content/press-releases-ani/e-commerce-lifestyle-brand-the-label-life-raises-funding-through-klub-121012001089_1.html'
  },
  {
    id: '12',
    des: '2021 to mark a new era for the Indian startup ecosystem',
    thumbFilename: 'news/media-cover-images/mint-cover-jan.jpg',
    logoFilename: 'news/logos/mint.png',
    date: '9 Jan 2021',
    link: 'https://www.livemint.com/opinion/columns/2021-to-mark-a-new-era-for-the-indian-startup-ecosystem-11609258202561.html'
  },
  {
    id: '11',
    thumbFilename: 'news/media-cover-images/inc42-dec.jpg',
    logoFilename: 'news/logos/inc42.png',
    des: '30 Startups that caught our eye in 2020',
    date: '31 Dec 2020',
    link: 'https://inc42.com/features/30-startups-to-watch-the-startups-that-caught-our-eye-in-2020/'
  },
  {
    id: '0',
    des: 'CoffeeMug, Klub join hands to invest in fast-growing D2C brands',
    thumbFilename: 'news/media-cover-images/zee-news.png',
    logoFilename: 'news/logos/zeeBusiness.png',
    date: '14 Nov 2020',
    link: 'https://www.zeebiz.com/small-business/news-coffeemug-klub-join-hands-to-invest-in-fast-growing-d2c-brands-entrepreneurs-investors-and-business-leaders-to-benefit-here-is-how-140224'
  },
  {
    id: '1',
    des: 'Meesho partners Klub to finance MSMEs, boost local manufacturing',
    thumbFilename: 'news/media-cover-images/yourstory_news2.png',
    logoFilename: 'news/logos/YOURSTORY.png',
    date: '19 AUG 2020',
    link: 'https://yourstory.com/smbstory/meesho-klub-partnership-msme-financing-manufacturing'
  },
  {
    id: '2',
    thumbFilename: 'news/media-cover-images/surge.png',
    logoFilename: 'news/logos/surge.png',
    des: 'Klub covered by Sequoia Capital as part of Surge accelerator programme',
    date: '01 AUG 2019',
    link: 'https://www.surgeahead.com/company/klub/'
  },
  {
    id: '3',
    thumbFilename: 'news/media-cover-images/SutraHr.png',
    logoFilename: 'news/logos/SutraHrLogo.png',
    des: 'Top 100 Startups in India to Watch in 2020',
    date: '31 DEC 2019',
    link: 'https://sutrahr.com/top-startups-india-2020-best-list'
  },
  {
    id: '4',
    thumbFilename: 'news/media-cover-images/Inc42Thumb.png',
    logoFilename: 'news/logos/inc42.png',
    des: '30 Startups To Watch: The Startups That Caught Our Eye In February 2020',
    date: '29 FEB 2020',
    link: 'https://inc42.com/features/30-startups-to-watch-the-startups-that-caught-our-eye-in-february-2020/'
  },
  {
    id: '5',
    thumbFilename: 'news/media-cover-images/yourstory.png',
    logoFilename: 'news/logos/YOURSTORY.png',
    des: 'Fintech startup Klub raises $2M pre-seed',
    date: '14 JAN 2020',
    link: 'https://yourstory.com/2020/01/fintech-startup-klub-funding-surge-sequoia-inmobi'
  },
  {
    id: '6',
    thumbFilename: 'news/media-cover-images/VCCircle.png',
    logoFilename: 'news/logos/VCC.png',
    des: 'Sequoia accelerator Surge leads pre-seed funding in fintech firm Klub',
    date: '14 JAN 2020',
    link: 'https://www.vccircle.com/sequoia-accelerator-surge-leads-pre-seed-funding-in-fintech-firm-klub'
  },
  {
    id: '7',
    thumbFilename: 'news/media-cover-images/inc42.png',
    logoFilename: 'news/logos/inc42.png',
    des: 'Sequoia Surge Startup Klub Raises $2 Mn Pre-Seed Funding',
    date: '14 JAN 2020',
    link: 'https://inc42.com/buzz/sequoia-surge-startup-klub-raises-2-mn-pre-seed-funding/'
  },
  {
    id: '8',
    thumbFilename: 'news/media-cover-images/news_minute.png',
    logoFilename: 'news/logos/tnm.png',
    des: 'Fintech startup Klub raises $2 mn pre-seed round from Surge',
    date: '14 JAN 2020',
    link: 'https://www.thenewsminute.com/article/fintech-startup-klub-raises-2-mn-pre-seed-round-surge-angel-investors-116060'
  },
  {
    id: '9',
    thumbFilename: 'news/media-cover-images/EconomicsTimes.png',
    logoFilename: 'news/logos/et.png',
    des: 'Sequoia Capital’s seed fund Surge backs 20 startups',
    date: '09 OCT 2019',
    link: 'https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/sequoia-capitals-seed-fund-surge-backs-20-startups/articleshow/71497265.cms'
  },
  {
    id: '10',
    thumbFilename: 'news/media-cover-images/yourstory.png',
    logoFilename: 'news/logos/YOURSTORY.png',
    des: 'Sequoia reveals startups in the second cohort for its Surge accelerator programme',
    date: '09 OCT 2019',
    link: 'https://yourstory.com/2019/10/sequoia-surge-cohort-startups-accelerator-southeast-asia-india'
  }
];
