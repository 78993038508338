/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import Styles from './styles.module.scss';
import ImageNew from '../../gatsby-image';

const TeamCard = ({ data, handleClick, selectedId }) => (
  <div className={Styles.wrapper}>
    {data.map((member) => (
      <td
        align="center"
        key={member.id}
        onClick={() => member.name && handleClick(member)}
        className={Styles.contentWrapper}
      >
        <tr align="center">
          <ImageNew
            filename={member.filename}
            alt={member.name}
            className={` ${Styles.imageView} ${selectedId === member.id ? Styles.selectedImg : ''}`}
          />
        </tr>
        <tr align="center" className={Styles.text}>
          {member.name && member.name.substr(0, member.name.indexOf(' '))}
        </tr>
        <tr align="center" className={Styles.subtext}>
          {member.designation}
        </tr>
      </td>
    ))}
  </div>
);
TeamCard.propTypes = {
  data: PropTypes.shape(),
  handleClick: PropTypes.func,
  selectedId: PropTypes.string
};
TeamCard.defaultProps = {
  data: [],
  handleClick: () => {},
  selectedId: ''
};
export default TeamCard;
