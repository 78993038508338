import React from 'react';

import Styles from './styles.module.scss';
import Image from '../../gatsby-image';

const NewsCard = ({ desc, date, link, logoFilename, thumbFilename }) => (
  <div className={Styles.wrapper}>
    <Image filename={thumbFilename} alt="" className={Styles.sliderImg} />
    <div className={Styles.contentWrapper}>
      <a href={link} target="_blank" rel="noopener noreferrer" className={Styles.link}>
        <p className={Styles.sliderHeader}>
          {desc}
        </p>
      </a>
      <div className={Styles.seperator} />
      <div className={Styles.imageView}>
        <Image filename={logoFilename} className={Styles.carousalImg} alt="" />
        <p className={Styles.sliderDate}>{date}</p>
      </div>
    </div>
  </div>
);
export default NewsCard;
