import React from 'react';

import InvestorCard from '../../investorCard';
import Styles from './styles.module.scss';

const Investors = ({ data }) => (
  <div className={Styles.wrapper}>
    {data.map((investor) => (
      <InvestorCard
        imgWp={investor.imgWp}
        link={investor.linkedInUrl}
        img={investor.img}
        mainText={investor.mainText}
        subText={investor.subText}
        color="#183B56"
        opacity={0.5}
      />
    ))}
  </div>
);
export default Investors;
