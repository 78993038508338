import React, { useState, useEffect } from 'react';
import { flatten } from 'lodash';
import TeamCarousel from 'nuka-carousel';

import {
  TeamCard,
  Investors,
  NewsCard,
  TeamMemberCard
} from '../../components/about';
import { teamData } from '../../mockdata/teamContent';
import { investorsSet1 } from '../../mockdata/investors';
import { newsList } from '../../mockdata/news';
import { Carousal } from '../../components/landing';
import AutoCarousal from '../../components/autoCarousal';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

import BannerSection from '../../components/banner';

import ArrowLeft from '../../../static/home/svg/Arrowleft.svg';
import ArrowRight from '../../../static/home/svg/Arrowright.svg';
import LinkedinImage from '../../../static/about/svg/linkedin.svg';
import ExtraSecImage from '../../../static/about/svg/extra_sec_image.svg';

import style from '../styles/about.module.scss';
import '../styles/slider.scss';
// import bannerStyles from '../styles/banner.module.scss';
import aboutBanner from '../../../static/banners/about.svg';
import { saveURLParamToSession } from '../../utils';

const About = () => {
  const [selectedMember, setSelectedMember] = useState(teamData[0][1]);
  const handleCardClick = (data) => {
    setSelectedMember(data);
  };

  const resetInitalValues = (index) => {
    if (index === 0) {
      setSelectedMember(teamData[index][1]);
    } else {
      setSelectedMember(teamData[index][0]);
    }
  };

  const handleLeftClick = (previousSlide, currentSlide) => {
    previousSlide();
    if (currentSlide - 1 >= 0) {
      resetInitalValues(currentSlide - 1);
    }
  };
  const handleRightClick = (nextSlide, currentSlide, noOfSlides) => {
    nextSlide();
    if (currentSlide + 1 <= noOfSlides) {
      resetInitalValues(currentSlide + 1);
    }
  };

  useEffect(() => {
    const hashValue = window
      && window.location
      && window.location.hash
      && window.location.hash.slice(1);
    if (hashValue) {
      const section = window && window.document && window.document.getElementById(hashValue);
      const headerOffset = 30;
      const investorOffset = window.outerWidth > 768 ? 500 : 320;
      const elementPosition = section && section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = hashValue === 'ourInvestors'
        ? elementPosition - (headerOffset + investorOffset)
        : elementPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition
      });
    }
  }, []);

  const onClick = () => {
    const section = window.document.getElementById('our-team');
    const headerOffset = 100;
    const elementPosition = section.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition
    });
  };

  const chunk = (array, size) => {
    const chunkedArr = [];
    let index = 0;
    while (index < array.length) {
      chunkedArr.push(array.slice(index, size + index));
      index += size;
    }
    return chunkedArr;
  };

  // forming 1d array to sort it as a whole
  let oneDArray = [];
  teamData.map((item) => item.map((childItem) => oneDArray.push(childItem)));

  // prevent first 4 element in sorting, sort rest elements
  const sortedData = oneDArray
    .slice(4, oneDArray.length - 4)
    .sort((a, b) => `${a.name}`.localeCompare(b.name));
  const mergedArray = [
    ...oneDArray.slice(0, 4),
    ...sortedData,
    ...oneDArray.slice(oneDArray.length - 4, oneDArray.length)
  ];
  const formattedTeamData = flatten(mergedArray).filter((team) => team.name);

  // web view - investor sort
  oneDArray = [];

  useEffect(() => {
    saveURLParamToSession();
  }, []);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Layout>
      <SEO
        title="Revenue Based Financing | By entrepreneurs, for entrepreneurs."
        description="Klub is a hybrid marketplace connecting brands with their loyal fans to get quick funding for their marketing, inventory and capex needs."
      />
      <BannerSection
        image={aboutBanner}
        heading="Built by entrepreneurs, "
        subHeading="for entrepreneurs"
        description="Vocal for Local"
        buttons={[
          <button type="button" onClick={onClick} className="btn btnklub1">
            Know More
          </button>
        ]}
      />

      {/* Section -our story */}
      <div className={style.section2Wrapper}>
        <div className="container">
          <div className={style.section2}>
            <div className={style.section21}>
              <h3>OUR STORY</h3>
              <h1>Connecting the dots..</h1>
              <p className={style.sectiontext}>
                Klub started out as a passion project but quickly evolved into
                the vision of revolutionizing growth capital for loved brands.
                We are a team of fintech, investment banking, venture capital,
                and technology veterans with an entrepreneur-first mindset.
              </p>
              <br />
              <p>
                Multiple great businesses never get traditional funding despite
                great products, increasing revenues, high margins, and a loyal
                customer base. Traditional investment structures either value
                hyper-growth or enforce onerous obligations. This never sat
                right with us! Klub was born out of our own experiences of
                building, assisting, and scaling businesses throughout our
                careers.
              </p>
              <br />
              <p>
                Klub was created to leverage data and the power of a patron
                community - not just to enable scalable and flexible financing
                for the brands but also to drive growth to them.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* extra info section */}
      <div id="extra-info-section" className={style.extraSecContainer}>
        <div className="container">
          <div className={`${style.section2} ${style.justifyBtwn}`}>
            <div className={`${style.imageContainer} ${style.imageFlex}`}>
              <img
                className={style.extraSecImage}
                src={ExtraSecImage}
                alt="Building Illustration"
              />
            </div>
            <div className={`${style.section21} ${style.flexBasisHalf}`}>
              <h3 className={style.upperCase}>
                Impacting local ecosystems through growth
              </h3>
              <h1>Enabling high-quality local businesses and their people</h1>
              <p className={style.sectiontext}>
                Klub exists to create an alternative financing model that
                enables local brands to grow. This growth, in turn, creates more
                jobs, a positive impact on supply chains, and local brand
                diversity.
              </p>
              <br />
              <p>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                We are privileged to have a raison d'etre that brings smiles to
                our brand partners, to their employees, and to a thriving local
                community.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* end extra info section */}

      <div id="our-team">
        {/* our team for desktop */}
        <div className="container">
          <div className={`${style.section3} ${style.section3WrapperDesktop}`}>
            <div className={style.section31}>
              <TeamCarousel
                resetInitalValues={resetInitalValues}
                defaultControlsConfig={{
                  pagingDotsStyle: { display: 'none', width: 0 }
                }}
                renderCenterLeftControls={({ previousSlide, currentSlide }) => (
                  <button
                    type="button"
                    onClick={() => {
                      handleLeftClick(previousSlide, currentSlide);
                    }}
                    disabled={currentSlide - 1 < 0}
                    className={style.carousalBtn}
                  >
                    <img src={ArrowLeft} alt="" />
                  </button>
                )}
                renderCenterRightControls={({
                  nextSlide,
                  currentSlide,
                  slideCount
                }) => (
                  <button
                    type="button"
                    onClick={() => {
                      handleRightClick(nextSlide, currentSlide, slideCount);
                    }}
                    disabled={currentSlide + 1 >= slideCount}
                    className={style.carousalBtn}
                  >
                    <img src={ArrowRight} alt="" />
                  </button>
                )}
                // eslint-disable-next-line consistent-return
                getControlsContainerStyles={(key) => {
                  switch (key) {
                    case 'CenterLeft':
                      return {
                        top: 'auto',
                        transform: 'none',
                        left: 'unset',
                        right: '50%',
                        outline: 'none'
                      };
                    case 'CenterRight':
                      return {
                        top: 'auto',
                        transform: 'none',
                        left: '50%',
                        right: 'unset',
                        outline: 'none'
                      };
                    case 'BottomCenter':
                      return { bottom: '24px' };
                    default:
                  }
                }}
              >
                {chunk(mergedArray, 8).map((team) => (
                  <>
                    <TeamCard
                      data={team}
                      handleClick={handleCardClick}
                      selectedId={selectedMember.id}
                    />
                  </>
                ))}
              </TeamCarousel>
            </div>
            <hr />
            <div className={`${style.section32} position-relative`}>
              <h3>OUR TEAM</h3>
              <h1>
                Teams that build together,
                <br />
                grow together.
              </h1>
              <div className={style.line} />
              <div className={style.mainText}>
                {selectedMember.name}
                {'  '}
                {' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={selectedMember.linkedinLink}
                  className={style.linkedinImage}
                >
                  <img
                    alt="Linkedin"
                    src={LinkedinImage}
                    width={20}
                    height={20}
                  />
                  {' '}
                </a>
                {' '}
              </div>
              <div className={style.designation}>
                {selectedMember.designation}
              </div>
              <p className={style.teamPersonDescription}>
                {selectedMember.description}
              </p>
              <ul className={`${style.ul} mb-3`}>
                {selectedMember
                  && selectedMember.data
                  && selectedMember.data.map((feature) => (
                    <li className={`${style.li}`}>{feature}</li>
                  ))}
              </ul>

              <div className="handsCont">
                <div className={`${style.line} mb-2`} />
                <h4 className={style.mainText}>Hungry for a bite?</h4>
                <p>
                  Passionate about building zero-to-one? Looking for a place
                  that blends the best of finance and technology? Ready to
                  un-learn and learn? We are looking for you!
                </p>
                <div className={style.btnWrapper}>
                  <a
                    target="_blank"
                    href="/careers"
                    rel="noopener noreferrer"
                    className="btn btnklub3"
                  >
                    Join Klub
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* our team for mobile */}
        <div className={`${style.section3} ${style.section3WrapperMobile}`}>
          <div className="container">
            <h3>OUR TEAM</h3>
            <h1>
              Teams that build together,
              <br />
              grow together.
            </h1>
          </div>
          <Carousal margin={true} autoScroll={false} name="about-team-mobile">
            {formattedTeamData
              && formattedTeamData.map((item) => (
                <TeamMemberCard
                  imgSrc={item.image}
                  imgWp={item.imageWp}
                  name={item.name}
                  data={item.data}
                  filename={item.filename}
                  designation={item.designation}
                  description={item.description}
                  linkedinLink={item.linkedinLink}
                />
              ))}
          </Carousal>
          <br />
          <br />
          <div className={style.section31}>
            <div className="container">
              <h4 className={style.mainText}>
                Hungry to get your hands dirty?
                {' '}
              </h4>
              <p>
                Passionate about building zero-to-one? Looking for a place that
                blends the best of finance and technology?
              </p>
              <div className={style.btnWrapper}>
                <a
                  target="_blank"
                  href="/careers"
                  rel="noopener noreferrer"
                  className="btn btnklub3"
                >
                  Join Klub
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Our investors section desktop */}
      <div id="ourInvestorsWeb" className={style.section4WrapperDesktop}>
        <div className="container">
          <div className={`${style.section4}`}>
            <h1>Our Investors</h1>
            <AutoCarousal autoplay={false} enableSwipe={false}>
              {chunk(investorsSet1, 14).map((element) => (
                <Investors data={element} />
              ))}
            </AutoCarousal>
          </div>
        </div>
      </div>

      {/* Our investors section mobile */}
      <div id="ourInvestors" className={style.section4WrapperMobile}>
        <div className="container">
          <div className={`${style.section4}`}>
            <h3 className={style.pl25}>OUR INVESTORS</h3>
            <AutoCarousal
              autoplay={false}
              enableSwipe={true}
              className="mobile-investor"
            >
              {chunk(investorsSet1, 12).map((element) => (
                <Investors data={element} />
              ))}
            </AutoCarousal>
          </div>
        </div>
      </div>

      {/* Klub in the news */}
      <div className={style.section5WrapperDesktop}>
        <div className="container">
          <h1>Klub in the news</h1>
        </div>
        <div className={style.klubNews}>
          <Carousal margin={true} autoScroll={false} name="testimonials-about">
            {newsList.map((item) => (
              <NewsCard
                logoFilename={item.logoFilename}
                thumbFilename={item.thumbFilename}
                key={item.id}
                desc={item.des}
                date={item.date}
                link={item.link}
              />
            ))}
          </Carousal>
        </div>
      </div>

      {/* Klub in the news section mobile */}
      <div className={style.section5WrapperMobile}>
        <div className="container">
          <div>
            <h3>KLUB IN THE NEWS</h3>
          </div>
        </div>
        <div className={style.klubNews}>
          <Carousal
            margin={true}
            autoScroll={false}
            name="testimonials-about-mobile"
          >
            {newsList.map((item) => (
              <NewsCard
                key={item.id}
                logoFilename={item.logoFilename}
                thumbFilename={item.thumbFilename}
                desc={item.des}
                date={item.date}
                link={item.link}
              />
            ))}
          </Carousal>
        </div>
      </div>
    </Layout>
  );
};
export default About;
