// eslint-disable-next-line import/prefer-default-export
export const teamData = [
  [
    {
      id: '1-0',
      name: null
    },
    {
      id: '1-1',
      name: 'Anurakt Jain',
      filename: 'team/Anurakt.png',
      area: '',
      designation: 'Co-founder & CEO',
      linkedinLink: 'https://www.linkedin.com/in/anuraktjain/',
      description: 'Part time blue-sky thinker. Full time catchphrase-creator.',
      data: [
        'InMobi, Vertex, DFJ, IndusView',
        'IIT-D, Wharton, CFA charterholder'
      ]
    },
    {
      id: '1-2',
      name: 'Ishita Verma',
      filename: 'team/Ishita.png',
      area: '',
      designation: 'Co-founder & COO',
      linkedinLink: 'https://www.linkedin.com/in/ishitaverma/',
      description:
        'When not rallying the troops at Klub, this species can be found with her nose in a book on some mountain. Disturb at your own peril!',
      data: [
        'Snyder Group (UAE), Unitus Capital, Kotak Institutional Equities',
        'Lady Shri Ram College, IIM-B'
      ]
    },
    {
      id: '1-3',
      name: null
    },
    {
      id: '1-4',
      name: 'Adwate Kumar',
      filename: 'team/Adwate.png',
      // data: ['SRI Capital, Brand Capital', 'IIT-R, IIM-C'],
      area: 'Technology',
      designation: `Strategic partnerships`,
      linkedinLink: 'https://www.linkedin.com/in/adwatekumar/',
      description:
        'Too poor to sit at home and play Dota, so, here I am helping Klub build partnerships.',
      data: ['SRI Capital, Brand Capital', 'IIT-R, IIM-C']
    },
    {
      id: '1-5',
      name: 'Deepak Bansal',
      filename: 'team/Deepak.png',
      area: 'Technology',
      designation: 'Technology',
      linkedinLink: 'https://www.linkedin.com/in/deepak-bansal/',
      description:
        '2 parts hustler + 2 parts baniya + 2 parts trustworthy + 4 parts tech - All for Klub.',
      data: [
        `Estee, Gulaq, InCred, InstaPaisa, SnapLion`,
        `Chitkara University`
      ]
    },
    {
      id: '1-6',
      name: 'Divya Singh',
      filename: 'team/Divya.png',
      area: 'Product Design',
      designation: 'Product Design',
      linkedinLink: 'https://www.linkedin.com/in/divisingh/',
      description:
        'Passionate about improving lives through design and constantly looking to learn new things everyday!',
      data: [
        `Jubilant, Biocon, Iraparenting`,
        `University of Lucknow`
      ]
    },
    {
      id: '1-7',
      name: 'Karthik Sekar',
      filename: 'team/Karthik.png',
      area: '',
      designation: 'Brand partnerships, Risk & Data',
      linkedinLink: 'https://www.linkedin.com/in/karthik-sekar-3640b39/',
      description:
        'I drink coffee, crack bad jokes and take care of brands & risk at Klub. Mostly in that order!',
      data: [`Moodys, Credit Suisse, Futures First `, `NIT-T`]
    }
  ],
  [
    {
      id: '2-0',
      name: 'Kushal ',
      filename: 'team/Kushal.png',
      area: '',
      designation: 'Analyst, Patron Ops',
      linkedinLink: 'https://www.linkedin.com/in/kushalsarawgi/',
      description:
        'Helping Klub build the next wave of growth before i grow old',
      data: [`AU Small Finance Bank`, `SVKM's NMIMS University`]
    },
    {
      id: '2-1',
      name: 'Mathpal Singh',
      filename: 'team/Mathpal.png',
      area: 'Technology',
      designation: 'Operations',
      linkedinLink: 'https://www.linkedin.com/in/mathpalsingh/',
      description:
        'Made like a gun, goes like a bullet – the way I work operations and my bike.',
      data: [`Optacredit Fintech, Royal Bank of Scotland`, `Loyola College`]
    },
    {
      id: '2-2',
      name: 'Mayank Kumar Shah',
      filename: 'team/Mayank.png',
      area: 'Finance',
      designation: 'Finance & Accounts',
      linkedinLink: 'https://www.linkedin.com/in/mayank-kumar-shah-451860148/',
      description:
        'Caffeine is optional when you are handling Finance & Accounts for Klub.',
      data: [`Drivezy`, `University of Allahabad`]
    },
    {
      id: '2-3',
      name: 'Sapan Karia',
      filename: 'team/Sapan.png',
      area: '',
      designation: 'Business Development',
      linkedinLink: 'https://www.linkedin.com/in/sapan-karia-7465a874/',
      description: 'Searching brands and untravelled roads, always!',
      data: [`Dayspring Foods`, `ICSI, CFA`]
    },
    {
      id: '2-4',
      name: 'Sukanya Iyer',
      area: '',
      filename: 'team/Sukanya.png',
      designation: `Founders' Staff`,
      linkedinLink: 'https://www.linkedin.com/in/sukanya-iyer-26741b45/',
      description: `A mostly harmless creature, I like my tea gingered and coffee filtered. At Klub, you’ll see me shapeshifting through roles as Founder’s Staff.`,
      data: [`EY, S&P Global`, `Miranda House`]
    },
    {
      id: '2-5',
      name: 'Sunil Nakka',
      area: 'Technology',
      filename: 'team/Sunil.png',
      designation: 'Product & Design',
      linkedinLink: 'https://www.linkedin.com/in/sunilnjoseph/',
      description: `While I'm always up for an adventure, I handle product like my favourites - Steve Jobs and Elon Musk!`,
      data: [`TeamWave`, `NIT Calicut`]
    }
  ],
  [
    {
      id: '3-0',
      name: 'Tharun H B',
      area: '',
      filename: 'team/Tharun.png',
      designation: 'Analyst',
      linkedinLink: 'https://www.linkedin.com/in/tharun-h-b-069231b8/',
      description: `When mom was grumbling about my MBA, I came to build Klub`,
      data: [`Manipal University`]
    }
  ]
];
