import React from 'react';
import PropTypes from 'prop-types';

import Styles from './styles.module.scss';
import LinkedinImage from '../../../../static/about/svg/linkedin.svg';
import Image from '../../gatsby-image';

const TeamCardMobile = ({ name, data, designation, description, linkedinLink, filename }) => (
  <div className={Styles.wrapper}>
    <div className={Styles.contentWrapper}>
      {filename && (
        <Image
          filename={filename}
          alt={name}
          className={Styles.memberImage}
        />
      )}
      <div className={Styles.detail}>
        {name && (
          <div className={`${Styles.mainText} d-block d-md-none`}>
            {name}
            {' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={linkedinLink}
              className={Styles.linkedinImage}
            >
              <img alt="Linkedin" src={LinkedinImage} width={20} height={20} />
              {' '}
            </a>
          </div>
        )}
        {designation && <div className={Styles.designation}>{designation}</div>}
      </div>
    </div>
    <div className={Styles.featureContainer}>
      {description && <p>{description}</p>}
      <ul className={Styles.subText}>
        {data && data.map((feature) => <li>{feature}</li>)}
      </ul>
    </div>
  </div>
);

TeamCardMobile.propTypes = {
  name: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.string),
  designation: PropTypes.string
};
TeamCardMobile.defaultProps = {
  name: '',
  data: [],
  designation: ''
};

export default TeamCardMobile;
