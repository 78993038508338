export const investorsSet1 = [
  {
    img: `investors/surge.png`,
    mainText: 'Surge',
    subText: ''
  },
  {
    img: `investors/AngelList.png`,
    mainText: 'AngelList',
    subText: ''
  },
  {
    img: `investors/BetterCapital.png`,
    mainText: 'Better Capital',
    subText: ''
  },
  {
    img: `investors/emvc.png`,
    mainText: 'EMVC Fintech Fund',
    subText: ''
  },
  {
    img: `investors/tracxn.png`,
    mainText: 'Tracxn Labs',
    subText: ''
  },
  {
    img: `investors/9Unicorns.png`,
    mainText: '9Unicorns',
    subText: ''
  },
  {
    img: `investors/Alter.png`,
    mainText: 'Alter Global',
    subText: ''
  },
  {
    img: `investors/GMO.png`,
    mainText: 'GMO VenturePartners',
    subText: ''
  },
  {
    img: `investors/trifectaCapital.png`,
    mainText: 'Trifecta Capital',
    subText: ''
  },
  {
    img: `investors/Naveen.png`,
    mainText: 'Naveen Tewari',
    subText: 'Founder & CEO, InMobi Group'
  },
  {
    img: `investors/kunal.png`,
    mainText: 'Kunal Shah',
    subText: 'Founder & CEO, CRED'
  },
  {
    img: `investors/Shradha.png`,
    mainText: 'Shradha Sharma',
    subText: 'Founder & CEO, Yourstory'
  },
  {
    img: `investors/AmitRanjan.png`,
    mainText: 'Amit Ranjan',
    subText: 'Architect, National eGovernance Division'
  },
  {
    img: `investors/DilipkumarKhandelwal.png`,
    mainText: 'Dilipkumar Khandelwal',
    subText: 'MD, Deutsche Bank'
  },
  {
    img: `investors/AmitGupta.png`,
    mainText: 'Amit Gupta',
    subText: 'Co-founder, Yulu'
  },
  {
    img: `investors/RamakantSharma.png`,
    mainText: 'Ramakant Sharma',
    subText: 'Co-founder, Livspace.com'
  },
  {
    img: `investors/Ram.png`,
    mainText: 'Ram Sahasranam',
    subText: 'Director, Athenahealth'
  },
  {
    img: `investors/RajeshYabaji.png`,
    mainText: 'Rajesh Yabaji',
    subText: 'Co-founder & CEO, Blackbuck'
  },
  {
    img: `investors/ShailendraSingh.png`,
    mainText: 'Shailendra Singh',
    subText: 'Founder & CEO, Samya.ai'
  },
  {
    img: `investors/NishantVerman.png`,
    mainText: 'Nishant Verman',
    subText: 'Co-founder, Prefr'
  },
  {
    img: `investors/KarumbaiahBK.png`,
    mainText: 'Karumbaiah BK',
    subText: 'CPO, YourStory'
  },
  {
    img: `investors/Amiya.png`,
    mainText: 'Amiya Pathak',
    subText: 'Co-founder & COO, EzCred'
  },
  {
    img: `investors/Sachin.png`,
    mainText: 'Sachin Maheshwari',
    subText: 'Co-founder & CEO, EzCred'
  },
  {
    img: `investors/SVMahadevan.png`,
    mainText: 'Dr. SV Mahadevan',
    subText: 'Director, Stanford School of Medicine'
  },
  {
    img: `investors/DhruvAgarwala.png`,
    mainText: 'Dhruv Agarwala',
    subText: 'CEO, Proptiger, Housing & Makaan'
  },
  {
    img: `investors/RahulTodkar.png`,
    mainText: 'Rahul Todkar',
    subText: 'Head of Data Science, LinkedIn'
  },
  {
    img: `investors/AbhishekNag.png`,
    mainText: 'Abhishek Nag',
    subText: 'Director of BD, Netflix'
  },
  {
    img: `investors/AbhijitGupta.png`,
    mainText: 'Abhijit Gupta',
    subText: 'Founder & CEO, Praxify'
  },
  {
    img: `investors/LalitKumar.png`,
    mainText: 'Lalit Kumar',
    subText: 'VP, Oracle'
  },
  {
    img: `investors/NitinSharma.png`,
    mainText: 'Nitin Sharma',
    subText: 'Founder, FirstPrinciples'
  },
  {
    img: `investors/PiyushShah.png`,
    mainText: 'Piyush Shah',
    subText: 'CEO, TruFactor'
  },
  {
    img: `investors/RaveenSastry.png`,
    mainText: 'Raveen Sastry',
    subText: 'Founder, Multiply Ventures'
  },
  {
    img: `investors/ShubhankarBhattacharya.png`,
    mainText: 'Shubhankar Bhattacharya',
    subText: 'Partner, Foundamental'
  },
  {
    img: `investors/HarshShah.png`,
    mainText: 'Harsh Shah',
    subText: 'Co-founder, Fynd'
  },
  {
    img: `investors/NipunMehra.png`,
    mainText: 'Nipun Mehra',
    subText: 'Founder & CEO, Ula'
  },
  {
    img: `investors/ArjunBansal.png`,
    mainText: 'Arjun Bansal',
    subText: 'Co-founder & CEO, XOKind'
  },
  {
    img: `investors/bhuvan_srinivasan.png`,
    mainText: 'Bhuvan Srinivasan',
    subText: 'Director, KKR'
  },

  {
    img: `investors/SajidRahman.png`,
    mainText: 'Sajid Rahman',
    subText: 'Co-founder & CEO, Digital Healthcare Solutions'
  },

  {
    img: `investors/AaryamanVirShah.png`,
    mainText: 'Aaryaman Vir Shah',
    subText: 'Co-founder, Prophetic Ventures'
  }
];
