/* eslint-disable react/jsx-no-target-blank */
import PropTypes from 'prop-types';
import React from 'react';

import Styles from './styles.module.scss';
import Image from '../gatsby-image';

const InvestorCard = ({ img, mainText, mainText2, subText, designation, company, color, opacity }) => (
  <div className={Styles.wrapper}>
    <div className={Styles.imgContainer}><Image filename={img} alt={mainText} /></div>
    <div className={Styles.mainText} style={{ color }}>{mainText}</div>
    {(mainText2 && mainText2 !== '') && (
      <div className={Styles.mainText} style={{ color }}>{mainText2}</div>
    )}
    {(subText && subText !== '') && (
      <div className={Styles.subText} style={{ opacity, color }}>{subText}</div>
    )}
    {(designation && designation !== '') && (
      <div className={Styles.designation} style={{ opacity, color }}>{designation}</div>
    )}
    {(company && company !== '') && (
      <div className={Styles.company} style={{ opacity, color }}>{company}</div>
    )}
  </div>
);
InvestorCard.propTypes = {
  img: PropTypes.string,
  mainText: PropTypes.string,
  mainText2: PropTypes.string,
  subText: PropTypes.string,
  designation: PropTypes.string,
  company: PropTypes.string
};
InvestorCard.defaultProps = {
  img: '',
  mainText: '',
  mainText2: '',
  subText: '',
  designation: '',
  company: ''
};
export default InvestorCard;
