/* eslint-disable react/button-has-type */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';
import styles from './styles.module.scss';
import ArrowLeft from '../../../static/home/svg/Arrowleft.svg';
import ArrowRight from '../../../static/home/svg/Arrowright.svg';

const AutoCarousel = (props) => {
  const { children, autoplay, duration, slideWidth, edgeEasing, zoomScale, opacityScale,
    slideIndex, slidesToShow, transitionMode, cellSpacing, resetInitalValues, enableSwipe, className } = props;
  return (
    <Carousel
      defaultControlsConfig={{
        pagingDotsStyle: { display: 'none', width: 0 },
        // pagingDotsStyle: { display: enableSwipe ? 'block' : 'none', width: 0 },
        nextButtonText: 'N',
        prevButtonText: 'P'
      }}
      className={`${styles.carousel} ${className || ''}`}
      slidesToShow={slidesToShow}
      transitionMode={transitionMode}
      cellSpacing={cellSpacing}
      slideIndex={slideIndex}
      renderCenterLeftControls={null}
      renderCenterRightControls={null}
      renderBottomLeftControls={({ previousSlide, currentSlide }) => (
        <button onClick={previousSlide} disabled={currentSlide - 1 < 0}>
          <img src={ArrowLeft} alt="" />
        </button>
      )}
      renderBottomRightControls={({ nextSlide, currentSlide, slideCount }) => (
        <button onClick={nextSlide} disabled={currentSlide + 1 >= slideCount}>
          <img src={ArrowRight} alt="" />
        </button>
      )}
      slideWidth={slideWidth}
      opacityScale={opacityScale}
      zoomScale={zoomScale}
      edgeEasing={edgeEasing}
      autoplay={autoplay}
      autoplayInterval={duration}
      swiping={enableSwipe}
      disableEdgeSwiping={true}
      wrapAround={false}
      afterSlide={(currentIndex) => resetInitalValues(currentIndex)}
    >
      {children}
    </Carousel>
  );
};
AutoCarousel.propTypes = {
  children: PropTypes.shape({}),
  autoplay: PropTypes.bool,
  slideWidth: PropTypes.number,
  edgeEasing: PropTypes.string,
  zoomScale: PropTypes.number,
  opacityScale: PropTypes.number,
  slidesToShow: PropTypes.number,
  transitionMode: PropTypes.string,
  cellSpacing: PropTypes.number,
  duration: PropTypes.number,
  resetInitalValues: PropTypes.func,
  slideIndex: PropTypes.number,
  enableSwipe: PropTypes.bool
};
AutoCarousel.defaultProps = {
  children: {},
  autoplay: true,
  duration: 20,
  slideWidth: 1,
  edgeEasing: 'easeLinear',
  zoomScale: 1,
  opacityScale: 1,
  slidesToShow: 1,
  transitionMode: 'scroll',
  cellSpacing: 0,
  resetInitalValues: () => { },
  slideIndex: 0,
  enableSwipe: true
};
export default AutoCarousel;
